<template>
    <el-main>
        <div class="cards">
            <el-card class="box-card">
                <div class="flex_row">
                    <div>提现账号</div>
                    <div style="font-weight:400;">银行卡</div>
                </div>
                <div class="card_info">{{ card.bank_info }}
                </div>
                <div style="display:flex;justify-content: flex-end;">
                    <el-button size="mini" type="primary" @click="dialogVisible = true">编辑</el-button>
                </div>
            </el-card>
            <el-card class="box-card">
                <div>可提现金额</div>
                <div class="num">￥{{ amount }}</div>
            </el-card>
            <div style="margin-left:50px; display: flex;align-items: center;">
                <el-button type="primary" @click="dialogVisible_withdraw = true">全部提现</el-button>
            </div>
        </div>
        <el-table :data="list" row-key="id" default-expand-all :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="order_sn" label="订单编号" align="center"></el-table-column>
            <el-table-column label="订单金额" align="center">
                <template v-slot="{ row }">￥{{ row.order.amount }}</template>
            </el-table-column>
            <el-table-column prop="rate" label="平台佣金" align="center">
                <template v-slot="{ row }">{{ row.rate * 100 }}%</template>
            </el-table-column>
            <el-table-column prop="percentage" label="结算比例" align="center">
                <template v-slot="{ row }">{{ row.percentage * 100 }}%</template>
            </el-table-column>
            <el-table-column prop="money" label="商家结算金额" align="center">
                <template v-slot="{ row }">￥{{ row.money }}</template>
            </el-table-column>
            <!--<el-table-column label="结算状态" align="center"></el-table-column>-->
            <el-table-column prop="money" label="订单完成时间" align="center">
                <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
            </el-table-column>
        </el-table>
        <Paging :total="total_number" :page="form.page" :pageNum="form.rows" @updatePageNum="updateData"></Paging>

        <el-dialog title="提现账号" :visible.sync="dialogVisible" width="40%">
            <el-form label-width="100px" :model="card" :rules="cardrules" ref="card">
                <el-form-item label="开户行">
                    <el-input size="small" v-model="card.bank_info" placeholder="请输入开户行"></el-input>
                </el-form-item>
                <el-form-item label="银行卡号">
                    <el-input size="small" v-model="card.bank_card" placeholder="请输入银行卡号"></el-input>
                </el-form-item>
                <el-form-item label="持卡人">
                    <el-input size="small" v-model="card.bank_name" placeholder="请输入持卡人"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmCard">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="提现账号" :visible.sync="dialogVisible_withdraw" width="40%">
            <el-form label-width="140px" label-position="left">
                <el-form-item label="提现账号">
                    <div>银行卡</div>
                </el-form-item>
                <el-form-item label="开户行">
                    <div>{{ card.bank_info }}</div>
                </el-form-item>
                <el-form-item label="银行卡号">
                    <div>{{ card.bank_card }}</div>
                </el-form-item>
                <el-form-item label="持卡人">
                    <div>{{ card.bank_name }}</div>
                </el-form-item>
                <el-form-item label="提现金额">
                    <div style="font-size: 30px;">￥{{ amount }}</div>
                </el-form-item>
                <el-form-item label="请确认是否提现？"></el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleWithdraw">确 定</el-button>
            </span>
        </el-dialog>
    </el-main>
</template>

<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            card: {
                bank_name: "",
                bank_card: "",
                bank_info: ""
            },
            list: [],
            form: {
                page: 1,
                rows: 10
            },
            total_number: 0,
            amount: '0.00',
            dialogVisible: false,
            cardrules: {
                bank_name: [{ required: true, message: '请输入开户行', trigger: 'blur' }],
                bank_card: [{ required: true, message: '请输入银行卡号', trigger: 'blur' }],
                bank_name: [{ required: true, message: '请输入持卡人', trigger: 'blur' }]
            },
            dialogVisible_withdraw: false
        };
    },
    created () {
        this.getBankCard();
        this.getAmount()
        this.getList()
    },
    methods: {
        getDateformat,
        updateData (val, status) {
            if (status == 0) {
                this.form.rows = val;
                this.getList();
            } else {
                this.form.page = val;
                this.getList();
            }
        },
        getBankCard () {
            this.$axios
                .post(this.$api.finance.getBankCard)
                .then(res => {
                    if (res.code == 0) {
                        if (res.result) {
                            this.card = res.result
                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        getAmount () {
            this.$axios
                .post(this.$api.finance.getAmount)
                .then(res => {
                    if (res.code == 0) {
                        this.amount = res.result.amount
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        getList () {
            this.$axios
                .post(this.$api.finance.orderList, this.form)
                .then(res => {
                    if (res.code == 0) {
                        this.list = res.result.list
                        this.total_number = res.result.total_number
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        confirmCard () {
            this.$refs['card'].validate((valid) => {
                if (valid) {
                    this.$axios
                        .post(this.$api.finance.editBankCard, this.card)
                        .then(res => {
                            if (res.code == 0) {
                                this.$message.success('保存成功')
                                this.dialogVisible = false
                                this.getBankCard();
                            }
                        });
                } else {
                    return false;
                }
            });
        },
        handleWithdraw () {
            this.$axios
                .post(this.$api.finance.withdraw)
                .then(res => {
                    if (res.code == 0) {
                        this.$message.success('提现成功')
                        this.dialogVisible_withdraw = false
                        this.getAmount()
                        this.getList()
                    }
                });
        }
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .cards {
        display: flex;
        margin-bottom: 20px;

        .box-card {
            width: 300px;
            font-weight: bold;

            .flex_row {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .num {
                font-size: 40px;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .card_info {
                font-weight: 400;
                line-height: 60px;
            }
        }

        .box-card+.box-card {
            margin-left: 50px;
        }
    }

    .el-table {
        flex: 1;

        /deep/ .el-input__inner {
            border-color: #fff;
        }

        /deep/ .el-input__inner:focus {
            border-color: #409eff;
        }

        border-top: 1px solid #ebeef5;
        border-left: 1px solid #ebeef5;
        border-right: 1px solid #ebeef5;
    }

    .edit {
        display: flex;
        align-items: center;

        span {
            margin-right: 5px;
        }

        i {
            font-size: 17px;
        }
    }

    .move .item {
        display: flex;
        align-items: center;
        padding-left: 30px;
        margin-bottom: 20px;
    }
}

/deep/ .el-table__expand-icon--expanded {
    // 这是点击后的旋转角度
    transform: rotate(0deg);
}

/deep/.el-table__expand-icon {
    .el-icon-arrow-right:before {
        //这是收起图标
        content: '\e723';
        font-size: 16px;
    }
}

/deep/ .el-table__expand-icon--expanded {
    .el-icon-arrow-right:before {
        // 这是展开图标
        content: '\e722';
        font-size: 16px;
    }
}

.descriptions {
    font-size: 18px;
    font-weight: bold;
    padding: 30px 0;
    display: flex;

    .descriptions_item+.descriptions_item {
        margin-left: 50px;
    }
}
</style>
